<template>
	<div class="page-cases">
		<div class="detail">
			<div class="custom-headline no-line">商户版-国美家商户扶持活动-美丽雅</div>
			<div class="tag">国美家营销</div>
			<div class="img-box" v-for="(i , index) in imgList" :key="index">
				<el-image :src="i" lazy></el-image>
			</div>
		</div>
		<!-- 放在外层与浏览器同宽 -->
		<div class="more-cases">
			<div class="title">更多精彩案例</div>
			<div class="cases-item">
				<div class="case-content-con" v-for="item in caseList" :key="item.id"
					@click="jump2casesDetail(item.id)">
					<el-image class="case-img" :src="item.image" fit="fill">
					</el-image>
					<div class="case-content-box">
						<div class="case-content-title">
							{{ item.title }}
						</div>
						<div class="case-content-des">{{ item.des }}</div>
						<div class="case-content-time">{{ item.date }}</div>
						<div class="case-content-sign">阅读全部 ></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imgList: [
				require("../assets/img/cases/demo3/1.jpg"),
				require("../assets/img/cases/demo3/2.jpg"),
				require("../assets/img/cases/demo3/3.jpg"),
				require("../assets/img/cases/demo3/4.jpg"),
				require("../assets/img/cases/demo3/5.jpg"),
				require("../assets/img/cases/demo3/6.jpg"),
				require("../assets/img/cases/demo3/7.jpg"),
				require("../assets/img/cases/demo3/8.jpg"),
				require("../assets/img/cases/demo3/9.jpg"),
				require("../assets/img/cases/demo3/10.jpg"),
				require("../assets/img/cases/demo3/11.jpg"),
				require("../assets/img/cases/demo3/12.jpg"),
				require("../assets/img/cases/demo3/13.jpg"),
				require("../assets/img/cases/demo3/14.jpg"),
				require("../assets/img/cases/demo3/15.jpg"),
				require("../assets/img/cases/demo3/16.jpg"),
			],
			caseList: [
				{
					id: 1,
					image: require("../assets/img/cases/demo1/2.png"),
					title: "开业45天，日销连续稳居前三，高于平均销售额120%的商户是如何经营的？",
					des: "自国美家美⻝美客商务⻝堂出现在大众视野。“香鼎坊”作为入驻品牌之一，在过去45天的经营过程中业绩 遥遥领先，在销售额和订单量上⻓期居于前列，交出了亮眼的答卷。",
					date: "2021.09.07",
				},
				{
          id: 2,
          image: require('../assets/img/cases/demo2/2.png'),
          title: "高出平均销售额220%+，揭秘快充时刻如何成为“食堂爆款”！",
          des: "2021年10月，快充时刻在美⻝美客⻝堂营业仅30 天，就以销售额高于平均水平220%的优势展现出了惊人的爆发力，成为美⻝美客最受欢迎的快餐品牌之一。",
          date: "2021.10.15",
        },
			],
		};
	},
	methods: {
		jump2casesDetail(id) {
      this.$router.push({
        name: `CasesDetail${id}`,
      })
    },
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
img {
	width: 100%;
	margin: 0 auto;
}
.page-cases {
	position: relative;
	width: 1200px;
	margin: auto;
	text-align: left;
	margin-top: 20px;
	.detail {
		.no-line {
			font-size: 26px;
			color: #1f2329;
			margin-bottom: 17px;
			&::after {
				background: none;
			}
		}
		.tag {
			font-size: 12px;
			color: #f08107;
			width: 90px;
			height: 32px;
			line-height: 32px;
			background-color: #feead2;
			text-align: center;
			margin-bottom: 17px;
		}
		.img-box {
			margin: 26px 0;
		}
		.text {
			text-indent: 2em;
		}
	}
}
.more-cases {
	.title {
		position: relative;
		font-size: 22px;
		font-weight: 500;
		color: #1f2329;
		text-align: center;
		margin-top: 90px;
		margin-bottom: 50px;
		&::after {
			content: "";
			position: absolute;
			bottom: -5px;
			left: 0;
			right: 0;
			width: 50px;
			height: 5px;
			margin: 0 auto;
			border-radius: 5px;
			background: linear-gradient(to right, #f6ba4f, #ea8427);
		}
	}
}
.cases-item {
	display: flex;
	.case-content-con {
		width: 386px;
		height: 523px;
		background: #fafafa;
		border-radius: 10px;
		margin-right: 60px;
		background-color: #fff;
		cursor: pointer;
		transition: box-shadow 0.3s linear;
		&:hover {
			cursor: pointer;
			box-shadow: 0px 4px 4px 0px #e9edf1;
		}
		.case-img {
			width: 385px;
			height: 201px;
			border-radius: 10px;
		}
		.case-content-box {
			width: 330px;
			padding: 40px 28px 26px 28px;
		}
		.case-content-title {
			width: 100%;
			height: 30px;
			font-size: 20px;
			font-family: HiraginoSansGB-W6, HiraginoSansGB;
			font-weight: normal;
			color: #1f2329;
			line-height: 30px;
			overflow: hidden;
			white-space: nowrap; /*把文本强制显示在一行*/
			text-overflow: ellipsis; /*超出部分显示...*/
		}
		.case-content-des {
			margin-top: 16px;
			width: 100%;
			// height: 74px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 24px;
			overflow: hidden;
			text-overflow: ellipsis; /*超出部分显示...*/
			display: -webkit-box;
			line-clamp: 3;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		.case-content-time {
			margin-top: 16px;
			height: 19px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 19px;
		}
		.case-content-sign {
			width: 91px;
			height: 24px;
			font-size: 18px;
			// font-family: MicrosoftYaHei;
			color: #eb8729;
			line-height: 24px;
			margin-top: 66px;
			cursor: pointer;
		}
	}
}
</style>